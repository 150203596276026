import { store } from "@/store";
import { defineAsyncComponent, markRaw } from "vue";
import { MessagePopupData } from "@/store/modules/messagePopupBox";
import { MessagePopupPriority } from "@/constant/component";

export const addPrizeWheelPopup = async (data: any) => {
  const PrizeWheelPopup = markRaw(
    defineAsyncComponent(
      () => import("@/components/activity/prizeWheel/PrizeWheelPopup.vue")
    )
  );
  store.dispatch("messagePopupBox/addNewMessage", {
    message: new MessagePopupData(
      PrizeWheelPopup,
      MessagePopupPriority.Priority_Highest,
      data
    ),
  });
};

export const addDailyCheckPopup = async (data: any) => {
  const DailyBonusView = markRaw(
    defineAsyncComponent(() => import("@/components/utils/DailyBonusView.vue"))
  );
  store.dispatch("messagePopupBox/addNewMessage", {
    message: new MessagePopupData(
      DailyBonusView,
      MessagePopupPriority.Priority_Highest,
      data
    ),
  });
};

export const addPublicityTextPopup = async (data: any) => {
  const PublicityTextPopup = markRaw(
    defineAsyncComponent(
      () => import("@/components/utils/PublicityTextPopup.vue")
    )
  );
  store.dispatch("messagePopupBox/addNewMessage", {
    message: new MessagePopupData(
      PublicityTextPopup,
      MessagePopupPriority.Priority_PublicityPopup,
      data
    ),
  });
};

export const addPublicityImagePopup = async (data: any) => {
  const PublicityImagePopup = markRaw(
    defineAsyncComponent(
      () => import("@/components/utils/PublicityImagePopup.vue")
    )
  );
  store.dispatch("messagePopupBox/addNewMessage", {
    message: new MessagePopupData(
      PublicityImagePopup,
      MessagePopupPriority.Priority_PublicityPopup,
      data
    ),
  });
};

export const addFirstRechargeDiscountPopup = async (data: any) => {
  const ActivityFirstChargeView = markRaw(
    defineAsyncComponent(
      () => import("@/components/activity/ActivityFirstChargeView.vue")
    )
  );
  store.dispatch("messagePopupBox/addNewMessage", {
    message: new MessagePopupData(
      ActivityFirstChargeView,
      MessagePopupPriority.Priority_Highest,
      data
    ),
  });
};
