enum CacheKey {
  TICKET = 1,
  DEVICE_ID = 2,
  TOKEN = 3,
  TOKEN_KEY = 4,
  GAMELIST = 5,
  APP_POLICY = 6,
  ACCOUNT_PHONE = 7,
  CATE_BTN_LIST = 10,
  USER_INFO = 11,
  VIP_CONFIG = 12,
  LAST_RECHARGE_CLOSE_TIP_TIME = 15,
  SHOW_REBATE_REQUIRED_INTRO_ONCE = 17,
  KS_TOKEN = 18,
  SMS_CLOSED = 19, //验证码功能是否关闭，默认为空不关闭
  DEMO_ACCOUNT = 21,
  LAUNCH_IMAGE = 22,
}

const increaseCache = (key: CacheKey | string, value: any) => {
  try {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  } catch (err) {
    localStorage.setItem(key.toString(), value.toString());
  }
};

const deleteCache = (key: CacheKey | string) => {
  localStorage.removeItem(key.toString());
};

const queryCache = (key: CacheKey | string, deff?: any): any => {
  const res = localStorage.getItem(key.toString());
  if (res == null || res == "" || res == "{}") {
    return deff ?? null;
  }

  try {
    return JSON.parse(res);
  } catch (err) {
    return res;
  }
};

const getCookies = (name: string) => {
  const arrStr = document.cookie.split("; ");
  for (let i = 0; i < arrStr.length; i++) {
    const temp = arrStr[i].split("=");
    if (temp[0] == name) {
      return decodeURI(temp[1]);
    }
  }

  return "";
};

export default {
  CacheKey,
  increaseCache,
  deleteCache,
  queryCache,
  getCookies,
};
